import React from 'react';

import css from './LocationAutocompleteInput.module.css';

const IconLocation = () => (
    <svg className={css.iconSvg} width="11" height="16" viewBox="0 0 11 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.5 0C2.46366 0 0 2.30393 0 5.14286C0 9.71429 5.5 16 5.5 16C5.5 16 11 9.71429 11 5.14286C11 2.30393 8.53634 0 5.5 0ZM5.5 8C5.06488 8 4.63953 7.86595 4.27775 7.61479C3.91596 7.36363 3.63398 7.00665 3.46746 6.58899C3.30095 6.17133 3.25738 5.71175 3.34227 5.26837C3.42716 4.82498 3.63669 4.4177 3.94437 4.09804C4.25204 3.77838 4.64404 3.56069 5.0708 3.47249C5.49756 3.3843 5.93991 3.42956 6.3419 3.60256C6.7439 3.77556 7.08749 4.06853 7.32923 4.44441C7.57097 4.8203 7.7 5.26221 7.7 5.71429C7.69936 6.32029 7.46737 6.90129 7.05493 7.3298C6.64249 7.75831 6.08328 7.99934 5.5 8Z" fill="#7C3AED" />
    </svg>

);

export default IconLocation;
